<template>
  <div class="cmp-autocomplete-field">
    <div :ref="'autoCompleteTop' + filterId" >
        <label>{{ filterId }}</label>
        <div class="input-group dropdown-aggr">
            <input
              :key="filterId"
              ref="textField"
              :placeholder="filterLabel"
              @focus="showAutoCompleteDropdown()"
              @keyup="searchString()"
              @keydown.enter="updateFiltersByEnter($event)"
              @keydown.tab="updateByTab()"
              @keyup.backspace="updateFiltersByBackspace()"              
              v-model="textField"
              v-outside-click="{ handler: 'hideDropdown', exclude: ['autoCompleteTop' + filterId ]}"
              :class="{ 'filled': textField !== '', 'invalid': !validInput }"
              class="auto-complete-input"
              type="text">
            <i class="kb-icon"></i>
            <div class='dropdown-button-wrapper'>
                <a class="dropdown-aggr-button" @click="showAutoCompleteDropdown()">
                  <i class="fa fa-chevron-down" aria-hidden="true" />
                </a>
            </div>
        </div>
        <div ref="autoCompleteList" class="auto-complete-data" v-if="showAutoComplete">
            <ul class="auto-complete-list">
                <li v-for="(option, index) in autoCompleteDataComputed" @click="updateFiltersByUser(option.key, true, false)" :key="index" class="auto-complete-option">
                    <span class="key">{{ option.key }}</span> <span class="counter">{{ option.value }}</span>
                </li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  props: {
    filterId: { type: String, required: false },
    filterLabel: { type: String, required: false },
    autoCompleteData: { type: Array, required: true },
    moduleCode: { type: String, required: true }
  },
  data () {
    return {
      showAutoComplete: false,
      textField: this.activeFilter,
      autoCompleteDataComputed: this.autoCompleteData
    }
  },
  computed: {
    ...mapState({
      state (state) {
        return state[this.namespace]
      },
      activeFilters (state, getters) {
        return getters[this.moduleCode + '/activeFilters']
      }
    }),
    activeFilter () {
      if (typeof this.activeFilters[this.filterId] !== 'undefined') {
        return typeof this.activeFilters[this.filterId].Values === 'object' ? this.activeFilters[this.filterId].Values[0] : this.activeFilters[this.filterId][0];
      } else {
        return '';
      }
    },
    validInput () {
      if (this.textField === '') {
        return true;
      } else {
        let valid = this.autoCompleteData.filter(item => item.key === this.textField).length > 0;
        return valid;
      }
    }
  },
  watch: {
    activeFilters () {
         this.textField = this.activeFilter;
      }
  },
  methods: {
    updateFiltersByUser (filterValue, changedFilterActive = true, focusOnNextInput = true, backspace = false) {
      if (!focusOnNextInput) {
        this.$emit('filterUpdated', { autoCompleteFieldRef: null, filterId: null });
      }
      let changedFilter;
      let filterActive = changedFilterActive;
      if (filterValue) {
        changedFilter = filterValue;
      } else {
        changedFilter = this.activeFilter;
        filterActive = false;
      }

      const payload = {
        filterActive: filterActive,
        filterId: this.filterId,
        filterValue: changedFilter,
        override: true,
        backspace: backspace
      };
      this.$emit('updateFilters', payload );
      this.hideDropdown();
      this.textField = changedFilter;
    },
    updateByTab () {
      if (this.validInput) {
        let searchTextAvail = this.autoCompleteData.filter(item => item.key === this.textField).length > 0;
      
        if (searchTextAvail || this.textField === '') {      
          this.updateFiltersByUser(this.textField);
        } else {
          this.showAutoComplete = false;
          return false;
        }
      }
      this.showAutoComplete = false;
    },
    updateFiltersByBackspace() {
      let fieldEmpty = this.textField === '';
      if (this.activeFilter !== '' && (this.autoCompleteData.filter(item => item.key === this.textField).length > 0 || fieldEmpty || this.activeFilter.substring(0, this.textField.length) === this.textField)) {              
          this.updateFiltersByUser(this.textField,true,true,true);
         setTimeout(x => {
           this.showAutoCompleteDropdown();          
         }, 100);
      }      
    },
    updateFiltersByEnter (ev) {
      if (ev) {
        ev.preventDefault();
      }

      if (this.validInput) {
        let searchTextAvail = this.autoCompleteData.filter(item => item.key === this.textField).length > 0;
      
        if (searchTextAvail || this.textField === '') {      
          this.updateFiltersByUser(this.textField);
        } else {
          this.showAutoComplete = false;
          return false;
        }

        this.$emit('goToSearchPage', {})
      }      
    },
    updateFiltersByKey () {      
      // When clearing current filter
      if (this.textField === '' && this.activeFilter !== '') {
        this.updateFiltersByUser(null, false);
      } else if (this.validInput && this.textField !== this.activeFilter) {
        let searchTextAvail = this.autoCompleteData.filter(item => item.key === this.textField).length > 0;        
        if (searchTextAvail || this.textField === '') {          
          this.updateFiltersByUser(this.textField);
        } else {
          this.showAutoComplete = false;
          return false;
        }
      } else {
        this.showAutoComplete = false;
        return false;
      }
    },
    hideDropdown () {
      this.showAutoComplete = false;
    },
    showAutoCompleteDropdown () {   
      if (this.textField === '') {
        this.updateFiltersByUser(null, false);        
      }
      else {
        this.updateFiltersByKey();
      }      
      
      setTimeout(x => {
           this.autoCompleteDataComputed = [...this.autoCompleteData];      
            this.showAutoComplete = true;
         }, 100);      
    
      this.$emit('filterUpdated', { autoCompleteFieldRef: this.$refs.textField, filterId: this.filterId });
    },
    searchString () {
      let filteredList = [];
      if (this.textField === '') {
        filteredList = [...this.autoCompleteData];
      } else if (this.activeFilter !== '' && !this.activeFilter.includes(this.textField) && this.textField.length < this.activeFilter.length && this.autoCompleteData.filter(data => data.key === this.textField).length > 0) {          
          this.updateFiltersByUser(this.textField);
      } else if (this.autoCompleteData !== undefined) {
        filteredList = this.autoCompleteData.filter(autoComplete => {
          return autoComplete.key.substring(0, this.textField.length) === this.textField;
        });
      } else if (!filteredList.length) {
        filteredList.push({ key: 'Geen producten gevonden' });
      }
      this.autoCompleteDataComputed = filteredList;
    }
  },
  created () {
    let activeFilter = this.activeFilter;
    this.textField = activeFilter;
  }
}
</script>

<style>
</style>
