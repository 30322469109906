<template>
  <div class="product-search uc_content_search_v5">
      <input type="text" 
        @keyup="searchString()"
        @keydown.enter="enterPressed($event)"
        v-model="searchText" class="form-control input" />        
        <div class="exact-match" v-if="exactKeyword !== null">
            
        </div>
        <span class="input-group-btn"></span>
        <div class="keywords-found" v-if="productKeywords.length > 0">
            <div class="keyword" v-for="keyword in productKeywords" v-bind:key="keyword.keywordString" v-on:click="dropdownClick(keyword.keywordString)">
                {{keyword.keywordString}}
            </div>
        </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
    data () {
    return {
            searchText: ''
        }
    },
    computed: {
        ...mapState('search', ['productKeywords', 'searchPage', 'exactKeyword']),
        ...mapGetters('elastic', { elasticIsActive: 'active' })
    },
    methods: {
        ...mapActions('search', {updateExactKeyword: 'updateExactKeyword', updateQueryString: 'updateQueryString', createSearchPageUrl: 'createSearchPageUrl', updateProductKeywords: 'updateProductKeywords', getFilters: 'getFilters'}),
        ...mapActions('elastic', { updateQueryStringElastic: 'updateQueryString'}),
        searchString () {
            this.updateQueryString({query: this.searchText});      
        },
        dropdownClick(val) {
            this.searchText = val;
            this.updateQueryString( { query: this.searchText, updateKeywords: false} );      
            this.handleSearchRequest();
        },
        enterPressed(ev) {
            if (ev) {
                ev.preventDefault();
            }
            this.handleSearchRequest();
        },
        handleSearchRequest() {            
            if (!this.elasticIsActive) {
                this.createSearchPageUrl();
                location.replace(this.searchPage);
            } else {
                this.updateQueryStringElastic(this.searchText);
                this.getFilters();
                this.searchText = '';
                this.updateProductKeywords([]);  
                this.updateExactKeyword(null);
            }
        },
    }
}
</script>

<style>

</style>