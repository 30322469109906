<template>
  <div class="cmp-search-bar advanced-search-bg HOME">
    <section class="advanced-search container">
      <div class="row">

        <div class="col-md-2" v-for="filter in computedFilters" :key="filter.id" :class="getFieldClass(filter.id)">
          <utlz-auto-complete-field
            :filterId="filter.id"
            :filterLabel="getFieldLabel(filter.id)"
            :autoCompleteData="filter.value"
            :moduleCode="'elastic'"
            @filterUpdated="filterUpdated($event)"
            @updateFilters="updateElasticFilters($event)"
          ></utlz-auto-complete-field>
        </div>

        <div class="col-md-2 search-btn">
          <div class="reset-btn-wrapper">
              <a class="clear-filter-btn" @click="clearAllFilters()">
                <i class="fa fa-refresh"></i>
              </a>
          </div>
        </div>
      </div>
    </section>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AutoCompleteField from './../auto-complete/AutoCompleteField.vue';
export default {
  components: {
    'utlz-auto-complete-field': AutoCompleteField
  },
  data () {
    return {
      lastInputFocus: null,
      formFields: {
        NORM: '',
        DIAM: '',
        LENGTE: ''
      }
    }
  },
  computed: {
    ...mapState('search', ['filters']),
    computedFilters () {
      const filters = [];
      this.filters.forEach(filter => {
        if (filter.id === 'NORM') {
          filters[0] = filter;
        } else if (filter.id === 'DIAM') {
          filters[1] = filter;
        } else if (filter.id === 'LENGTE') {
          filters[2] = filter;
        } else if (filter.id === 'KW') {
          filters[3] = filter;
        } else if (filter.id === 'OPPERV') {
          filters[4] = filter;
        }
      });
      return filters;
    }
  },
  methods: {
    ...mapActions('elastic', { updateElasticModFilters:'updateFilters', clearElasticFilters: 'clearFilters' }),    
    ...mapActions('search', { updateSearchFilters:'updateFilters' }),    
    clearAllFilters () {
      this.clearElasticFilters();
      this.lastInputFocus = null;
    },
    filterUpdated ({ autoCompleteFieldRef, filterId }) {
      if (autoCompleteFieldRef === null) {
        this.lastInputFocus = null;
      } else {
        // Do not set focus on input if it is the last field in the searchbar
        this.lastInputFocus = autoCompleteFieldRef;
      }
    },
    updateElasticFilters (payload) {      
      this.updateSearchFilters(payload);
      if (!payload.backspace || payload.filterValue === '') {
        this.updateElasticModFilters(payload);
      }
    },
    getFieldClass (id) {
      switch (id) {
        case 'DIAM':
          return 'diameter';
        case 'KW':
          return 'quality';
        case 'LENGTE':
          return 'length';
        case 'NORM':
          return 'code';
        case 'OPPERV':
          return 'surface';
        default:
          return '';
      }
    },
    getFieldLabel (id) {
      const labels = window.vue.translations.label;
      switch (id) {
        case 'DIAM':
          return labels.lbl_search_diameter;
        case 'KW':
          return labels.lbl_search_quality;
        case 'LENGTE':
          return labels.lbl_search_length;
        case 'NORM':
          return labels.lbl_search_code;
        case 'OPPERV':
          return labels.lbl_search_surface;
        default:
          return 'BRRRR';
      }
    }
  },
  updated () {
    // Replace vb advanced searchbar with vuejs
    window.$('#elastic-search-bar').append(window.$('.cmp-search-bar'));
    window.$('#elastic-search-bar + .advanced-search-bg.header').remove();
    if (this.lastInputFocus !== null) {
      this.lastInputFocus.focus();
    }
  }
}
</script>

<style>

</style>
