<template>
  <div class="cmp-shopping-cart-control utlz-reset">
    <div class="icon-wrapper" @click="toggleCart()">
      <i class="uws-icon uws-cart" aria-hidden="true"></i>
      <span class="total-counter btn-u">{{ totalProductsCount }}</span>
    </div>

    <transition name="fadein-cart">
      <div v-if="cartOpen">

        <!-- BESPOKE TUIN-130: Added v-if="usingQuickOrder" so the shoppingcart panel stays open on the quick order page -->
        <div v-if="!usingQuickOrder" @click="toggleCart()" class="close-target"></div>
        <!-- END BESPOKE TUIN-130 -->

        <div class="shopping-cart-panel">

          <span class="close-button close-panel-mobile" @click="toggleCart()">
            <i class="fa fa-close" aria-hidden="true"></i>
          </span>

          <div class="panel-header flex-row-nowrap align-baseline">
            <!-- BESPOKE KOB: DIsabled icon -->
            <!-- <i class="uws-icon uws-cart" aria-hidden="true"></i> -->
            <!-- END BESPOKE KOB -->
            <h2>
              <span v-translation="{ type: 'title', code: 'title_shopping_cart_header' }"></span>
              <span class="counter">({{ totalProductsCount }})</span>
            </h2>
          </div>

          <!-- BESPOKE KOB -->
          <i v-if="usingQuickOrder" @click="toggleCart()" class="fa fa-minus-square close-panel"></i>
          <!-- END BESPOKE KOB -->

          <!-- BESPOKE KOB: Wrapped loader in template -->
          <template v-if="!usingQuickOrder">
            <div v-if="loadingOrderLines">
              <div v-for="index in 4" :key="index" class="ph-silhouette utlz-row">
                <span class="silhouette-img utlz-col-2"></span>
                <div class="ph-paragraph utlz-col-10">
                  <span class="silhouette-line"></span>
                  <span class="silhouette-line"></span>
                </div>
              </div>
            </div>
          </template>
          <!-- END BESPOKE KOB -->

          <template v-if="showOrderLines">
            <ul class="order-lines">
              <li v-for="orderLine in orderLines" :key="orderLine.lineId" class="orderline-row flex-row-nowrap">
                <div v-if="!hideShoppingCartImages" class="prod-img">
                  <!-- BESPOKE KOB: Added cloudinary image -->
                  <img :src="orderLine.product.imageUrl ? orderLine.product.imageUrlSmall : '/documents/productimages/not-available-small.jpg'" />
                  <!-- END BESPOKE KOB -->
                </div>
                <div class="prod-desc">
                  <a :href="orderLine.product.targetUrl">
                    <h3 class="prod-title header-small">{{ orderLine.product.shortDescription }}</h3>
                  </a>
                  <div class="quantity-wrapper">
                    <!-- BESPOKE KOB-305: Disabled quantity indicator in shoppingcart overlay -->
                    <!-- <span class="quantity">{{ orderLine.computedQuantity * 100 }} x</span> -->
                    <!-- END BESPOKE KOB-305 -->
                    <utlz-product-price :prices="orderLine.totals"
                                        v-if="orderLine.product.prices.length"></utlz-product-price>
                  </div>
                </div>
                <div class="delete">
                  <span class="close-button small grey" @click="deleteOrderLine(orderLine.lineId)">
                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                  </span>
                </div>
              </li>
            </ul>
          </template>

          <div v-if="shoppingCartEmpty" class="empty-cart-message col-md-12">
            <h2 v-translation="{ type: 'title', code: 'title_shopping_cart_empty' }"></h2>
          </div>

          <div class="fade-white"></div>

          <utlz-shopping-cart-totals v-if="showOrderLines" :format="shoppingCartControlTotalsFormat"></utlz-shopping-cart-totals>

          <div v-if="showOrderLines" class="actions">
            <a :href="shoppingCartUrl" class="button theme-primary block">
              <i class="uws-icon uws-cart" aria-hidden="true"></i>
              <span v-translation="{ type: 'button', code: 'button_view_shopping_cart' }"></span>
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
  import ShoppingCartTotals from 'src/components/webshop/shopping-cart/ShoppingCartTotals.vue';
  export default {
    components: {
      'utlz-shopping-cart-totals': ShoppingCartTotals,
      'utlz-product-price': ProductPrice
    },
    data() {
      return {
        cartOpen: false
      }
    },
    computed: {
      ...mapGetters(['language', 'hideShoppingCartImages', 'userLoggedOn', 'orderNotLoggedOn']),
      ...mapGetters('shoppingCart', ['orderLines', 'loadingOrderLines', 'totalProductsCount', 'shoppingCartEmpty', 'shoppingCartControlTotalsFormat']),
      shoppingCartUrl() {
        if (this.orderNotLoggedOn && !this.userLoggedOn) {
          return `/${this.language}/webshop/paymentprocess/shopping_cart.aspx`;
        } else {
          return `/${this.language}/webshop/paymentprocess/single_checkout.aspx`;
        }
      },
      showOrderLines() {
        return this.orderLines.length > 0 && this.orderLines[0].product !== null;
      },
      // BESPOKE KOB
      usingQuickOrder () {
        return window.vue.modules.quickOrder !== undefined;
      }
    // END BESPOKE KOB
    },
    methods: {
      ...mapActions('shoppingCart', ['initShoppingCart', 'getShoppingCartTotalAndCount', 'deleteOrderLine']),
      toggleCart() {
        this.cartOpen = !this.cartOpen;
        if (this.cartOpen && !window.vue.modules.shoppingCartOverview) {
          this.initShoppingCart();
        }
      }
    },
    created() {
      this.getShoppingCartTotalAndCount();
      // BESPOKE TUIN-145
    // Shoppingcart control open on page load when using quick order
    if (this.usingQuickOrder) {
      this.toggleCart();
    }
    // END BESPOKE TUIN-145
    }
  };
</script>

<style>
</style>
