import axios from 'axios';
import { Product } from 'src/models/product.model';
import { OrderLine } from 'src/models/order-line.model';
import { formatPrice } from 'src/utilities/helpers';

const state = {
  products: [],
  orderLines: [],
  productCodes: [],
  totalProductsCount: 0,
  shoppingCartInitizalized: false,
  loadingOrderLines: false,
  validatingStock: false,
  shoppingCartTotals: {
    CouponsTax: { price: '€ 0,00', rawPrice: 0 },
    CouponsNet: { price: '€ 0,00', rawPrice: 0 },
    CouponsTotal: { price: '€ 0,00', rawPrice: 0 },
    OrderCostsTax: { price: '€ 0,00', rawPrice: 0 },
    OrderCostsNet: { price: '€ 0,00', rawPrice: 0 },
    OrderCostsTotal: { price: '€ 0,00', rawPrice: 0 },
    ShippingCostsTax: { price: '€ 0,00', rawPrice: 0 },
    ShippingCostsNet: { price: '€ 0,00', rawPrice: 0 },
    ShippingCosts: { price: '€ 0,00', rawPrice: 0 },
    OrderLinesTotalTax: { price: '€ 0,00', rawPrice: 0 },
    OrderLinesTotalNet: { price: '€ 0,00', rawPrice: 0 },
    OrderLinesTotal: { price: '€ 0,00', rawPrice: 0 },
    OrderTotal: { price: '€ 0,00', rawPrice: 0 }
  },
  shoppingCartValid: false,
  shoppingCartEmpty: false,
  minimumOrderQuantityPrice: '€ 0,00',
  minimumOrderQuantityRawPrice: 0,
  orderLinesTotalPrice: '€ 0, 00',
  orderLinesTotalRawPrice: 0,

  invalidOrderLines: [],
  totalOrderLines: 0,
  stockValid: false,
  couponCode: '',
  hasCoupon: false,
  shoppingCartTotalsFormat: [],
  shoppingCartControlTotalsFormat: []
};

const getters = {
  productCodes (state) {
    return state.productCodes;
  },
  orderLines (state) {
    return state.orderLines;
  },
  totalProductsCount (state) {
    return state.totalProductsCount;
  },
  loadingOrderLines (state) {
    return state.loadingOrderLines;
  },
  validatingStock (state) {
    return state.validatingStock;
  },
  shoppingCartInitizalized (state) {
    return state.shoppingCartInitizalized;
  },
  productInformationEndpoint (state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.productInformationEndpoint;
    const client = rootGetters.clientCode;
    const language = rootGetters.language;
    return `${endpoint}/${client}?language=${language}`;
  },
  shoppingCartTotals (state) {
    return state.shoppingCartTotals;
  },
  shoppingCartTotalsFormat (state) {
    return state.shoppingCartTotalsFormat;
  },
  shoppingCartControlTotalsFormat (state) {
    return state.shoppingCartControlTotalsFormat;
  },
  stockValid (state, getters, rootState, rootGetters) {
    if (!rootGetters.stockLimit) {
      return true;
    } else {
      return state.stockValid;
    }
  },
  invalidOrderLines (state) {
    return state.invalidOrderLines;
  },
  shoppingCartEmpty (state) {
    return state.shoppingCartEmpty;
  },
  orderLinesTotal (state) {
    return state.shoppingCartTotals.OrderLinesTotal;
  },
  minimumOrderQuantity (state) {
    return state.minimumOrderQuantity;
  },
  minimumOrderQuantityPrice (state) {
    return state.minimumOrderQuantityPrice;
  },
  minimumOrderQuantityRawPrice (state) {
    return state.minimumOrderQuantityRawPrice;
  },
  orderLinesTotalPrice (state) {
    return state.orderLinesTotalPrice;
  },
  orderLinesTotalRawPrice (state) {
    return state.orderLinesTotalRawPrice;
  },
  minimumOrderQuantityValid (state, getters, rootState, rootGetters) {
    if (rootGetters.useMinimumOrderQuantity) {
      return getters.orderLinesTotalRawPrice - getters.minimumOrderQuantityRawPrice > 0;
    } else {
      return true;
    }
  },
  couponCode (state) {
    return state.couponCode;
  },
  hasCoupon (state) {
    return state.hasCoupon;
  },
  showCheckoutButton (state, getters) {
    return getters.stockValid && getters.minimumOrderQuantityValid;
  },
  // BESPOKE KOB
  useQuickOrder(state) {
    return window.vue.modules.quickOrder !== undefined;
  }
  // END BESPOKE KOB
};

const mutations = {
  initConfig (state, config) {
    state.shoppingCartTotalsFormat = config.shoppingCartTotalsFormat;
    state.shoppingCartControlTotalsFormat = config.shoppingCartControlTotalsFormat;
  },
  setProductCodes (state, productCodes) {
    state.productCodes = productCodes;
  },
  setProducts (state, products) {
    const orderLines = [...state.orderLines];
    orderLines.forEach(orderLine => {
      orderLine.setProduct(products.filter(product => product.id === orderLine.productId)[0]);
      orderLine.setComputedQuantity();
    });
  },
  setOrderLines (state, orderLines) {
    state.orderLines = orderLines;
    state.totalOrderLines = orderLines.length;
  },
  setLoadingOrderLines (state, loading) {
    state.loadingOrderLines = loading;
  },
  setValidatingStock (state, validating) {
    state.validatingStock = validating;
  },
  addToCart (state, productRow) {
    state.shoppingCartRows.push(productRow);
  },
  setTotalAndCount (state, totalProducts) {
    state.totalProductsCount = totalProducts;
  },
  setShoppingCartEmpty (state, empty) {
    state.shoppingCartEmpty = empty;
  },
  setProductPrice (state, productWithPrice) {
    state.orderLines.forEach((orderLine) => {
      if (orderLine.productId === productWithPrice.id) {
        orderLine.product.prices = productWithPrice.prices;
        orderLine.setOrderLineTotal();
      }
    });
  },
  setProductStock (state, productWithStock) {
    state.orderLines.forEach(orderLine => {
      if (orderLine.productId === productWithStock.id) {
        orderLine.product.setStock(productWithStock.stock);
        orderLine.setComputedQuantity();
      }
    });
  },
  updateOrderLineQuantity (state, { lineId, quantity, unitCode, totalCount, showPrices }) {
    let index = state.orderLines.findIndex(orderLine => orderLine.lineId === lineId);
    state.orderLines[index].quantity = quantity;
    state.orderLines[index].unitCode = unitCode;

    // update total orderline quantities
    let prodCode = state.orderLines[index].productId;
    state.orderLines.forEach(orderLine => {
      if (orderLine.productId === prodCode) {
        orderLine.totalOrderLinesQuantity = totalCount;
      }
    });
    if (showPrices) {
      state.orderLines[index].setOrderLineTotal();
    }
    state.orderLines[index].setComputedQuantity();
  },
  updateOrderLineComment (state, { lineId, comment }) {
    let index = state.orderLines.findIndex(orderLine => orderLine.lineId === lineId);
    state.orderLines[index].comments = comment;
  },
  deleteOrderLine (state, { orderLineIndex, totalCount }) {
    const orderLines = [ ...state.orderLines ];
    let prodCode = state.orderLines[orderLineIndex].productId;
    state.orderLines.forEach(orderLine => {
      if (orderLine.productId === prodCode) {
        orderLine.totalOrderLinesQuantity = totalCount;
      }
    });
    orderLines.splice(orderLineIndex, 1);
    state.orderLines = orderLines;
  },
  setShoppingCartTotals (state, totals) {
    let totalsObj = {};
    Object.keys(totals).forEach(key => {
      totalsObj[key] = { price: formatPrice(totals[key]), rawPrice: totals[key] };
    });
    state.shoppingCartTotals = totalsObj;
    state.minimumOrderQuantityPrice = formatPrice(totals.MinimumOrderQuantity);
    state.minimumOrderQuantityRawPrice = totals.MinimumOrderQuantity;
    state.orderLinesTotalPrice = formatPrice(totals.OrderLinesTotal);
    state.orderLinesTotalRawPrice = totals.OrderLinesTotal;
  },
  setCouponCode (state, { CouponCode, HasCoupon }) {
    state.couponCode = CouponCode;
    state.hasCoupon = HasCoupon;
  },
  setShoppingCartInitizalized (state, intitialized) {
    state.shoppingCartInitizalized = intitialized;
  },
  checkStockState (state) {
    const orderLines = [...state.orderLines];
    let invalidLines = [];
    orderLines.forEach(orderLine => {
      if (orderLine.product.customBooleans.STOCK_PRODUCT) {
        if (orderLine.product.stock.stockTotal < orderLine.totalOrderLinesQuantity) {
          invalidLines.push(orderLine);
        }
      }
    });
    if (invalidLines.length) {
      state.stockValid = false;
      state.invalidOrderLines = invalidLines;
    } else {
      state.stockValid = true;
      state.invalidOrderLines = [];
    }
  },
  emptyShoppingCart (state) {
    state.orderLines = [];
    state.totalProductsCount = 0;
  }
}

const actions = {
  initShoppingCart ({ getters, commit, dispatch }) {
    dispatch('getOrderLines');
    commit('setShoppingCartInitizalized', true);
  },
  getShoppingCartTotals ({ commit, rootGetters, dispatch }) {
    axios.post(rootGetters.shoppingCartTotalsEndpoint, {})
      .then(res => {
        const data = res.data.d.Totals;
        commit('setShoppingCartTotals', data.Prices);
        dispatch('renderCheckoutButton');
        if (rootGetters.useCoupons) {
          commit('setCouponCode', data.CouponInfo);
        }
      });
  },
  getShoppingCartTotalAndCount ({ commit, rootGetters, getters }) {
    axios.post(rootGetters.shoppingCartTotalAndCountEndpoint, {})
      .then(res => {
        const data = res.data.d;
        if (data.Status === 'Success') {
          // BESPOKE KOB
          // Use the orderlines count instead of the orderline quantity sums
          commit('setTotalAndCount', data.Lines.length);
          // END BESPOKE KOB
        } else {
          // TODO ERROR HANDLING
        }
      });
  },
  getOrderLines ({ commit, dispatch, rootGetters }) {
    commit('setLoadingOrderLines', true);
    axios.post(rootGetters.orderLinesEndpoint, {})
      .then(res => {
        const data = res.data.d;
        if (data.Status === 'Success') {
          dispatch('getShoppingCartTotals');
          if (data.Lines.length) {
            const productCodes = data.Lines.map(orderLine => orderLine.ProductId);
            commit('setShoppingCartEmpty', false);
            commit('setProductCodes', productCodes);
            commit('setOrderLines', data.Lines.map(orderLine => new OrderLine(orderLine)));
            dispatch('getProductInformation');
          } else {
            commit('setShoppingCartEmpty', true);
            dispatch('renderCheckoutButton');
          }
        } else if (data.Status === 'Failure') {
          dispatch('renderCheckoutButton');
          window.updateErrorMessage(data.ErrorMessage);
        }
        commit('setLoadingOrderLines', false);
      });
  },
  getProductInformation ({ getters, rootGetters, commit, dispatch }) {
    axios.post(getters.productInformationEndpoint, getters.productCodes)
      .then(res => {
        const products = res.data.map(product => new Product(product));
        commit('setProducts', products);

        if (rootGetters.showStock) {
          dispatch('getProductStock', products);
        } else {
          products.forEach(product => { product.setStock({ stockTotal: 0 }); })
        }

        if (rootGetters.showPrices) {
          dispatch('getProductPrices', products);
        }
      });
  },
  validateShoppingCartStock ({ commit, getters, rootGetters, dispatch }) {
    commit('setValidatingStock', true);
    let counter = 0; let orderLinesLength = getters.orderLines.length;
    const products = getters.orderLines.map(orderLine => orderLine.product);
    products.forEach(product => {
      axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
        .then(res => {
          counter++;
          product.setStock(res.data.d);
          commit('setProductStock', product);
          if (counter === orderLinesLength && rootGetters.stockLimit) {
            commit('checkStockState');
            if (getters.invalidOrderLines.length) {
              const message = window.vue.translations.message.message_invalid_stock_orderlines;
              const offset = window.$('#stock-warning').offset().top - 75;
              window.updateErrorMessage(message);
              window.scrollTo({ top: offset, behavior: 'smooth' });
              dispatch('renderCheckoutButton');
            } else {
              dispatch('checkout');
            }
            commit('setValidatingStock', false);
          }
        });
    });
  },
  getProductStock ({ rootGetters, getters, commit, dispatch }, products) {
    let counter = 0; let orderLinesLength = getters.orderLines.length;
    products.forEach(product => {
      axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
        .then(res => {
          counter++;
          product.setStock(res.data.d);
          commit('setProductStock', product);
          if (counter === orderLinesLength && rootGetters.stockLimit) {
            commit('checkStockState');
            dispatch('renderCheckoutButton');
          }
        });
    });
  },
  getProductPrices ({ commit, getters, dispatch, rootGetters }, products) {
    let priceRequestWrapper = {};
    priceRequestWrapper.CustomerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    priceRequestWrapper.Pricelist = rootGetters.customerPriceList;
    priceRequestWrapper.Products = products.map(prod => {
      let ret = {};
      ret.ProductId = prod.id;
      ret.ProductGroup = prod.discountGroup;

      ret.ProductUnit = '';
      if (prod.units !== undefined && prod.units !== null) {
        ret.ProductUnit = prod.units.length > 0 ? prod.units[0].code : '';
      }
      return ret;
    });

    let endpoint = rootGetters.productPriceEndpoint + 'prices/' + rootGetters.clientCode

    axios.post(endpoint, priceRequestWrapper)
      .then(res => {
        res.data.forEach(price => {
          let product = products[products.findIndex(x => x.id === price.productId)];

          const pricesObj = [];
          Object.keys(price.volumes).forEach(key => {
            pricesObj.push({
              price: price.volumes[key].price,
              basePrice: price.volumes[key].basePrice,
              isSalesAction: price.volumes[key].isSalesAction,
              quantity: Math.round(parseInt(key))
            });
          });

          product.setPrices(pricesObj);
          commit('setProductPrice', product);
        });
      });
  },
  updateOrderLineQuantity ({ rootGetters, commit, dispatch }, orderLine) {
    return new Promise((resolve, reject) => {
      axios.post(rootGetters.updateOrderLineEndpoint, { orderLineToBeUpdatedObj: orderLine })
        .then(res => {
          const data = res.data.d;
          if (data.Status === 'Success') {
            commit('updateOrderLineQuantity', {
              lineId: data.UpdatedLine.LineId,
              quantity: parseInt(data.UpdatedLine.Quantity),
              unitCode: data.UpdatedLine.UnitCode,
              totalCount: data.UpdatedLine.TotalCount,
              showPrices: rootGetters.showPrices
            });
            commit('setShoppingCartTotals', data.Totals.Prices);
            dispatch('getShoppingCartTotalAndCount');

            if (rootGetters.stockLimit) {
              commit('checkStockState');
            }

            dispatch('renderCheckoutButton');

            resolve(true);
          } else {
            reject();
            window.updateErrorMessage(data.ErrorMessage);
            dispatch('getOrderLines');
          }
        });
    });
  },
  updateOrderLineComment ({ rootGetters, commit }, orderLine) {
    return new Promise((resolve, reject) => {
      axios.post(rootGetters.updateOrderLineEndpoint, { orderLineToBeUpdatedObj: orderLine })
        .then(res => {
          const data = res.data.d;
          if (data.Status === 'Success') {
            commit('updateOrderLineComment', { lineId: data.UpdatedLine.LineId, comment: data.UpdatedLine.Comments });
            resolve();
          } else {
            reject();
            window.updateErrorMessage(data.ErrorMessage);
          }
        });
    });
  },
  deleteOrderLine ({ rootGetters, getters, commit, dispatch }, orderLineId) {
    const orderLineIndex = getters.orderLines.findIndex(orderLine => orderLine.lineId === orderLineId);
    const orderLine = getters.orderLines[orderLineIndex];
    const payload = {
      LineId: orderLine.lineId,
      ShoppingCartId: orderLine.shoppingCartId,
      ProductId: orderLine.productId,
      Comments: orderLine.comments,
      Quantity: orderLine.quantity,
      UnitCode: orderLine.unitCode,
      IsFree: orderLine.isFree
    }
    axios.post(rootGetters.deleteOrderLineEndpoint, { lineToBeDeletedObj: payload }).then(res => {
      const data = res.data.d;
      if (data.Status === 'Success') {
        commit('deleteOrderLine', {
          orderLineIndex: orderLineIndex,
          totalCount: data.LineToBeDeleted.TotalCount
        });
        // BESPOKE KOB
        // Use the orderlines count instead of the orderline quantity sums
        commit('setTotalAndCount', getters.orderLines.length);
        // END BESPOKE KOB
        commit('setShoppingCartTotals', data.Totals.Prices);
        if (rootGetters.stockLimit) {
          commit('checkStockState');
          dispatch('renderCheckoutButton');
        }
        if (getters.orderLines.length === 0) {
          commit('setShoppingCartEmpty', true);
        }
      } else if (data.Status === 'Failure') {
        window.updateErrorMessage(data.ErrorMessage);
      }
    });
  },
  applyCoupon ({ rootGetters, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(rootGetters.addCouponEndpoint, { couponCode: payload })
        .then(res => {
          const response = res.data.d;
          const status = response.Status;
          if (status === 'Success') {
            commit('setCouponCode', { CouponCode: payload, HasCoupon: true });
            commit('setShoppingCartTotals', response.Totals.Prices);
            resolve(response.Message);
          } else if (status === 'CouponUsed' || status === 'NotFound') {
            commit('setCouponCode', { CouponCode: '', HasCoupon: false });
            reject(response.Message);
          }
        });
    });
  },
  removeCoupon ({ rootGetters, getters, commit }) {
    return new Promise((resolve, reject) => {
      axios.post(rootGetters.removeCouponEndpoint, { couponCode: getters.couponCode })
        .then(res => {
          const response = res.data.d;
          if (response.Status === 'Success') {
            commit('setCouponCode', { CouponCode: '', HasCoupon: false });
            commit('setShoppingCartTotals', response.Totals.Prices);
            resolve(response.Message);
          } else if (response.Status === 'Failure') {
            window.updateErrorMessage(response.Message);
            reject();
          }
        });
    });
  },
  emptyShoppingCart ({ rootGetters, commit }) {
    axios.post(rootGetters.emptyShoppingCartEndpoint, {}).then(res => {
      const data = res.data.d;
      if (data.Status === 'Success') {
        commit('emptyShoppingCart');
        commit('setShoppingCartEmpty', true);
        window.updateOrderMessage(data.Message);
      } else if (data.Status === 'Failure') {
        window.updateErrorMessage(data.Message);
      }
    });
  },
  renderCheckoutButton ({ getters }) {
    if (!getters.showCheckoutButton) {
      window.$('.checkout-button').addClass('disabled');
    } else {
      window.$('.checkout-button').removeClass('disabled');
    }
  },
  hidePageElements () {
    window.$('.vuejs-hook').hide();
  },
  checkout () {
    window.$('.uc_payment .inputbutton').click();
  },
  addToCart ({ rootGetters, dispatch }, payload) {
    let endpoint = rootGetters.orderProductEndpoint;
    let userLoggedOn = rootGetters.userLoggedOn;
    const products = Array.isArray(payload) ? payload : [payload];
    return new Promise((resolve, reject) => {
      axios.post(endpoint, JSON.stringify({ products: products, loggedInStatus: userLoggedOn })).then(
        res => {
          const response = res.data.d;
          if (response.errorMessages) {
            if (response.errorMessages === 'log in status changed') {
              window.location.replace('/webshop/login.aspx?RedirectUrl=' + window.location.pathname + window.location.search);
            } else {
              window.updateErrorMessage(response.errorMessages);
            }
          } else {
            resolve(true);
            dispatch('getShoppingCartTotalAndCount');

            // BESPOKE KOB
            // On the quickorder page, keep the shoppingcart control panel open,
            // so after adding something to cart, we need to get the orderlines again
            if (getters.useQuickOrder !== undefined) {
              dispatch('getOrderLines');              
            }
            // END BESPOKE KOB

            // REFERS TO FUNCTION CALLS IN CUSTOM.JS
            if (response.errorMessages) {
              window.updateErrorMessage(response.errorMessages);
            }

            var message = response.order_message;
            if (response.totalProductsAdded > 0) {
              window.updateOrderMessage(message);
            }
            // END CUSTOM.JS FUNCTION CALLS
          }
        })
        .catch(err => {
          // @TODO Error handling
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
