<template>
  <div class="cmp-search-bar advanced-search-bg HOMEEE">
    <section class="advanced-search container">
      <div class="row" v-if="!noProductsFound">

        <div class="col-md-2" v-for="filter in computedFilters" :key="filter.id" :class="getFieldClass(filter.id)">
          <utlz-auto-complete-field
            :filterId="filter.id"
            :filterLabel="getFieldLabel(filter.id)"
            :autoCompleteData="filter.value"
            :moduleCode="'search'"
            @filterUpdated="filterUpdated($event)"
            @updateFilters="updateSearchFilters($event)"
            @goToSearchPage="goToSearchPage($event)"
            :ref="'AutoComplete' + filter.id"
          ></utlz-auto-complete-field>
        </div>

        <div class="col-md-2 search-btn">
          <div class="reset-btn-wrapper">
              <a class="clear-filter-btn" @click="clearAllFilters()">
                <i class="fa fa-refresh"></i>
              </a>
          </div>
          <div class="reset-btn-wrapper" v-if="!elasticIsActive">
              <a class="clear-filter-btn" @click="goToSearchPage()">
                <i class="fa fa-search"></i>
              </a>
          </div>
        </div>
      </div>
    </section>
</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import AutoCompleteField from './../auto-complete/AutoCompleteField.vue';
export default {
    components: {
        'utlz-auto-complete-field': AutoCompleteField
    },
    data () {
    return {
        lastInputFocus: null        
        }
    },
    computed: {
        ...mapState('search', ['filters', 'searchPage']),
        ...mapGetters('elastic', { elasticIsActive: 'active', noProductsFound: 'noProductsFound' }),
        computedFilters () {
        const filters = [];
        this.filters.forEach(filter => {
            if (filter.id === 'NORM') {
            filters[0] = filter;
            } else if (filter.id === 'DIAM') {
            filters[1] = filter;
            } else if (filter.id === 'LENGTE') {
            filters[2] = filter;
            } else if (filter.id === 'KW') {
            filters[3] = filter;
            } else if (filter.id === 'OPPERV') {
            filters[4] = filter;
            }
        });        
        for( var i = 0; i < filters.length; i++){ if ( filters[i] === undefined) { filters.splice(i, 1); i--; }}
        return filters;
        }
    },
    created () {
        this.initSearchBar();
    },
    methods: {
        ...mapActions('search', { initSearchBar: 'initSearchBar', updateFilters: 'updateFilters' , createSearchPageUrl: 'createSearchPageUrl', clearSearchFilters: 'clearFilters'}),
        ...mapActions('elastic', { updateElasticFilters: 'updateFilters', clearElasticFilters: 'clearFilters' }),
        clearAllFilters () {
            this.clearSearchFilters();
            if (this.elasticIsActive) {
                this.clearElasticFilters();
            }            
            this.$nextTick(() =>{
                this.$refs['AutoComplete' + this.computedFilters[0].id][0].$refs['textField'].focus();
            });
        },
        goToSearchPage(payload) {
            if (!this.elasticIsActive) {
                this.createSearchPageUrl();
                location.replace(this.searchPage);
            }
        },
        updateSearchFilters(payload) {
            this.updateFilters(payload);
            if (this.elasticIsActive && (!payload.backspace || payload.filterValue === '')) {
                this.updateElasticFilters(payload);
            }
        },
        filterUpdated ({ autoCompleteFieldRef, filterId }) {
            if (autoCompleteFieldRef === null) {
                this.lastInputFocus = null;
            } else {
                // Do not set focus on input if it is the last field in the searchbar
                this.lastInputFocus = autoCompleteFieldRef;
            }
        },
        getFieldClass (id) {
            switch (id) {
                case 'DIAM':
                    return 'diameter';
                case 'KW':
                    return 'quality';
                case 'LENGTE':
                    return 'length';
                case 'NORM':
                    return 'code';
                case 'OPPERV':
                    return 'surface';
                default:
                    return '';
            }
        },
        getFieldLabel (id) {
            const labels = window.vue.translations.label;
            switch (id) {
                case 'DIAM':
                    return labels.lbl_search_diameter;
                case 'KW':
                    return labels.lbl_search_quality;
                case 'LENGTE':
                    return labels.lbl_search_length;
                case 'NORM':
                    return labels.lbl_search_code;
                case 'OPPERV':
                    return labels.lbl_search_surface;
                default:
                    return 'BRRRR';
            }
        }
    },
    updated () {
        // Replace vb advanced searchbar with vuejs
        if (this.lastInputFocus !== null) {
        this.lastInputFocus.focus();
        }
    }
}
</script>