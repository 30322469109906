<template>
  <div class="cmp-product-stock-signal uc_product_stock">
    <a @click="toggleNotificationModal()" class="link-signal btn-text" v-translation="{ type: 'label', code: 'label_signal_me_stock' }">
    </a>
    <div v-if="showNotificationModal" class="modal-default-large bg-fade-black">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 v-translation="{ type: 'title', code: 'title_email_signal_header' }"></h1>
          </div>
          <div class="modal-body">
            <div class="signal-text intro">
              <p v-translation="{ type: 'text', code: 'text_email_signal_stock'}"></p>
            </div>
            <div class="form-control-group">
              <input ref="emailAddress" type="text" class="input signal-email" v-model="emailAddress"/>
            </div>
            <div class="signal-text removal">
              <p v-translation="{ type: 'text', code: 'text_signal_stock_removed_after' }"></p>
            </div>
          </div>
          <div class="modal-footer">
            <a @click="toggleNotificationModal()" v-translation="{ type: 'button', code: 'button_signal_cancel' }" class="button theme-primary signal-cancel"></a>
            <a @click="sendEmailNotification()" v-translation="{ type: 'button', code: 'button_signal_confirm' }" class="button theme-primary signal-confirm"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
export default {
  props: {
    productCode: { type: String, required: true }
  },
  data () {
    return {
      emailAddress: '',
      showNotificationModal: false
    }
  },
  computed: {
    ...mapGetters(['bespokeEndpoints'])
  },
  methods: {
    sendEmailNotification () {
      const payload = { signalEmail: this.emailAddress, productCode: this.productCode };
      axios.post("/Bespoke/AjaxMethods.aspx/SendSignalStockRequest", payload).then(
        res => {
          const response = res.data.d;
          if (response.errorMessage) {
            // refers to function call in custom.js
            window.updateErrorMessage(response.errorMessage);
          } else {
            // refers to function call in custom.js
            window.updateOrderMessage(response.confirmText);
            this.toggleNotificationModal();
          }
        }
      ).catch(err => {
        console.log(err);
      })
    },
    toggleNotificationModal () {
      this.showNotificationModal = !this.showNotificationModal;
    }
  }
}
</script>

<style>

</style>
