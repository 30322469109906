<template>
  <div class="cmp-product-configurator saw-configurator" :class="{ 'disabled': disableForm || reloading }">
    
    <template v-if="showConfirmOrder">
      <div tabindex="-1" class="modal bs-example-modal-lg in confirm-modal" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
              
              <div class="modal-header">
                  <h2 v-translation="{ type: 'title', code: 'title_custpipe_confirm_modal'}"></h2>
              </div>
              <div class="modal-body">
                  <div v-translation="{ type: 'text', code: 'text_custpipe_confirm_modal'}"></div>
              </div>
              <div class="modal-footer">
                <a @click="confirmOrder()" class="btn-u sawlist-btn" v-translation="{ type: 'button', code: 'button_pipeconf_modal' }"></a>
              </div>
          </div>
        </div>
      </div>
    </template>

    <!-- <template v-if="showErrorMessage">
      <div tabindex="-1" class="modal bs-example-modal-lg in confirm-modal" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
              
              <div class="modal-header">
                  <h2 v-translation="{ type: 'title', code: 'title_calc_error'}"></h2>
              </div>
              <div class="modal-body">
                  <div v-translation="{ type: 'text', code: 'text_calc_error'}"></div>
              </div>
              <div class="modal-footer">
                <a @click="closeError()" class="btn-u sawlist-btn" v-translation="{ type: 'button', code: 'button_pipeconf_modal' }"></a>
              </div>
          </div>
        </div>
      </div>
    </template> -->

    <header>
      <h1 v-translation="{ code: 'title_saw_conf', type: 'title' }"></h1>
      <p v-translation="{ code: 'text_config_para', type: 'text' }"></p>
    </header>

    <div class="configurator-wrapper row" v-if="showOrderProduct">
      <div class="row">
        <div class="col-md-12">                
          <div class="col-md-12">
            <div class="input-row">
              <i aria-hidden="true" class="field-icon diam-icon"></i>
                
              <div class="field-wrapper">        
                <label for="diameter" class="input-label" v-translation="{ code: 'lbl_search_diameter', type: 'label' }"></label>
                <div class="select-wrap">
                  <span class="dd-button">
                    <i aria-hidden="true" class="fa fa-chevron-down"></i>
                  </span>                  
                  <select class="input-field" v-model="selectedDiameter" @change="updateDiameters()">
                    <option value="">{{defaultOptionValue}}</option>
                    <option v-for="diameter in diameters" :key="'val-' + diameter" :value="diameter" :selected="diameter === selectedDiameter">
                      {{ diameter }}
                    </option>
                  </select>
                </div>

              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="input-row">
              <i aria-hidden="true" class="field-icon quality-icon"></i>
                
              <div class="field-wrapper">        
                <label for="quality" class="input-label" v-translation="{ code: 'lbl_search_quality', type: 'label' }"></label>
                <div class="select-wrap">
                  <span class="dd-button">
                    <i aria-hidden="true" class="fa fa-chevron-down"></i>
                  </span>                     
                  <select class="input-field" v-model="selectedQuality" @change="updateQualities()">
                    <option value="">{{defaultOptionValue}}</option>
                    <option v-for="quality in qualities" :key="'val-' + quality" :value="quality" :selected="quality === selectedQuality">
                      {{ quality }}
                    </option>
                  </select>
                </div>

              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="input-row">
              <i aria-hidden="true" class="field-icon surface-icon"></i>
                
              <div class="field-wrapper">        
                <label for="diameter" class="input-label" v-translation="{ code: 'lbl_search_surface', type: 'label' }"></label>
                <div class="select-wrap">
                  <span class="dd-button">
                    <i aria-hidden="true" class="fa fa-chevron-down"></i>
                  </span>
                  <select class="input-field" v-model="selectedSurfaceTreament" @change="updateSelectedSurfaceTreatment()">
                    <option value="">{{defaultOptionValue}}</option>
                    <option v-for="(key, index) in Object.keys(surfaceTreatments)" :key="'val-' + index" :value="key" :selected="key === selectedSurfaceTreament">
                      {{ key }}
                    </option>
                  </select>
                </div>

                <template v-if="selectedSurfaceTreamentIsThermic">
                  <div id="dbl-finish">
                    <label for="chb-double-finish">                      
                      <input type="checkbox" id="chb-double-finish" v-model="isDoubleFinish" @change="changedDoubleFinish()">
                      <span v-translation="{ type: 'label', code: 'label_dbl_finish'}"></span>
                    </label>
                  </div>
                </template>

              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="input-row">
              <i aria-hidden="true" class="field-icon length-icon">
              </i>
              <div class="field-wrapper">
                <label for="length" class="label-wrapper input-label">
                  <span class="input-label" v-translation="{ code: 'lbl_search_length', type: 'label' }"></span>
                  <span class="minmax" v-translation="{ code: 'lbl_search_lengthmin_max', type: 'label' }"></span>
                </label>
                <input class="input-field" id="length" type="number" v-model="length" @change="updateLength()" v-on:keyup.enter="$event.target.blur()">
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="input-row">              
              <span class="icon-ph"></span>
              <div class="field-wrapper">
                <label for="quantity" class="input-label" v-translation="{ code: 'label_quantity_conf', type: 'label' }">
                </label>
                <input type="number" id="quantity"  class="input-field" v-model="quantity" @change="updateQuantity()" v-on:keyup.enter="$event.target.blur()">
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="input-row">
              <span class="icon-ph"></span>
              <div class="field-wrapper">        
                <label for="service-cost" class="input-label" v-translation="{ code: 'label_exp_ser_costs', type: 'label' }">
                </label>
                <div class="select-wrap">
                  <span class="dd-button">
                    <i aria-hidden="true" class="fa fa-chevron-down"></i>
                  </span>
                  <select id="service-cost" class="input-field" v-model="selectedExpeditedServiceCostId" @change="updateSelectedServiceCost()">
                    <option value="">{{defaultOptionValue}}</option>
                    <option v-for="costObj in expeditedServiceCosts" :key="costObj.id" :value="costObj.id" :selected="costObj.id === selectedExpeditedServiceCostId">
                      {{ costObj.desc }}
                    </option>
                  </select>
                </div>

              </div>
            </div>
          </div>

        </div>  
      </div>
      <div class="actions-row col-md-12">

        <div class="calc">
          <span v-if="disableForm" class="loader"></span>
          <a class="btn-u sawlist-btn" @click="calculatePipe()" v-translation="{ type: 'button', code: 'button_calc' }" :class="{ 'disabled': !formIsValid }">            
          </a>
        </div>

        <div class="prices">
          <div v-if="selectedPipe !== null" class="calc-price price-wrapper">
            <div class="price-per-100">
              <span class="price-label" v-translation="{ code: 'label_your_price_100', type: 'label'}"></span>
              <span class="price-100">
                {{ formattedPricePer100 }}
              </span>
            </div>
            <div class="calc-price">              
              <span class="price-label" v-translation="{ code: 'label_your_price', type: 'label'}"></span>
              <span class="price">
                {{ formattedPrice }}
              </span>
            </div>            
          </div>
          <div v-else class="price-wrapper">
            <span class="calc-msg">
              <span v-translation="{ type: 'label', code: 'label_calc_msg' }"></span>
            </span>
          </div>
        </div>
        <div class="order">
          <a @click="orderPipe()" class="btn-u order-btn" :class="{ 'disabled': selectedPipe === null }" v-translation="{ type: 'button', code: 'button_order' }">            
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { formatPrice } from 'src/utilities/helpers';
import QuantityButtons from 'src/components/webshop/order-product/blocks/QuantityButtons.vue';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  components: {
    'utlz-quantity-buttons': QuantityButtons,
    'utlz-product-price': ProductPrice
  },
  data() {
    return {
      selectedDiameter: "",
      selectedQuality: "",
      selectedSurfaceTreament: "",
      surfaceTreatments: {},
      qualities: [],
      diameters: [],
      length: "",
      minLength: 50,
      maxLength: 2999,
      quantity: "",
      minQuantity: 1,
      urlParams: {},
      calculatedPrice: 0,
      selectedPipe: null,
      disableForm: false,
      showConfirmOrder: false,
      formIsValid: false,
      showErrorMessage: false,
      defaultOptionValue: window.vue.translations.label.label_choose_val,
      noSurfaceTreatmentAvailableValue: window.vue.translations.label.label_no_surface_trmnt,
      quantityAndLengthAreValid: false,
      isDoubleFinish: false,
      serviceCostMultiplier: 1,
      selectedExpeditedServiceCostId: "2w",
      expeditedServiceCosts: [
        { id: '1w', cost: 20, desc: window.vue.translations.label.label_exp_ser_costs_1w },
        { id: '2w', cost: 5, desc: window.vue.translations.label.label_exp_ser_costs_2w },
        { id: '3w', cost: 0, desc: window.vue.translations.label.label_exp_ser_costs_3w }
      ],
      reloading: false,
      reloaded: false
    }
  },
  computed: {    
    ...mapGetters(['bespokeEndpoints', 'customerId', 'updateOrderLineEndpoint', 'language']),  
    ...mapGetters('shoppingCart', ['orderLines']),
    ...mapGetters(['showOrderProduct']),
    ...mapGetters('sawConfigurator', ['doubleFinishSpecVal']),
    formattedPrice() {
      return formatPrice(this.calculatedPrice);
    },
    formattedPricePer100() {
      return formatPrice(this.selectedPipe.productPrice * 100 * this.serviceCostMultiplier);
    },
    selectedSurfaceTreamentIsThermic() {
      return this.surfaceTreatments[this.selectedSurfaceTreament] === true;
    }
  }, 
  methods: {
    ...mapMutations('sawConfigurator', ['updateActiveFilters']),
    ...mapActions('sawConfigurator', ['initSawConfigurator', 'updateFilters']),
    setFormIsValid() {
      var isValid = true;
      if (this.selectedDiameter === '' || this.selectedQuality === '' || this.selectedSurfaceTreament === '' 
          || (this.length === '' || this.length < this.minLength) 
          || (this.quantity === '' || this.quantity == 0) || this.selectedExpeditedServiceCostId === '') {
        isValid = false;
      }
      this.formIsValid = isValid;
    },
    getSawlistEndpoint() {
      var endpointBase = this.bespokeEndpoints.sawApiHost;
      var debtorId = this.customerId;
      var quality = this.selectedQuality;
      var surfaceTreatment = this.selectedSurfaceTreament === this.noSurfaceTreatmentAvailableValue ? "" : this.selectedSurfaceTreament;
      var diameter = this.selectedDiameter;
      var length = this.length;
      var quantity = this.quantity;

      var endpoint = `${endpointBase}/calculation?debtorId=${debtorId}&quality=${quality}&surfaceTreatment=${surfaceTreatment}&diameter=${diameter}&length=${length}&quantity=${quantity}`;
      
      if (this.isDoubleFinish) {
        endpoint += '&doubleFinish=true'
      }

      return endpoint;
    },
    resetSelectedPipe() {
      this.selectedPipe = null;
    },
    updateLength() {
      var length = parseInt(this.length);
      if (length > this.maxLength) {
        this.length = this.maxLength;
      } else if (length < this.minLength) {
        this.length = this.minLength;
      }
      this.selectedPipe = null;
    },
    updateQuantity() {
      var quantity = parseInt(this.quantity);
      if (quantity < this.minQuantity) {
        this.quantity = this.minQuantity;
      }
      this.selectedPipe = null;
    },
    updateQualities() {
      this.getDiameters();      
      this.getSurfaceTreatments();
    },
    updateDiameters() {
      this.getQualities();
      this.getSurfaceTreatments();
    },
    updateSelectedSurfaceTreatment() {
      this.setDoubleFinish();
      this.resetSelectedPipe();
    },  
    setDoubleFinish() {
      if (this.selectedSurfaceTreament !== this.surfaceTreatmentDoubleFinishKey) {
        this.isDoubleFinish = false;
      }
    },
    changedDoubleFinish() {
      this.selectedPipe = null;
    },  
    updateSelectedServiceCost() {
      if (this.selectedExpeditedServiceCostId !== '') {        
        var serviceCostObj = this.expeditedServiceCosts.find(x => x.id === this.selectedExpeditedServiceCostId);
        this.serviceCostMultiplier = serviceCostObj.cost / 100 + 1;
      } else {
        this.serviceCostMultiplier = 1;
      }
      this.resetSelectedPipe();
    },
    getDiameters() {
      this.disableForm = true;
      this.selectedPipe = null;

      var url = new URL(`${this.bespokeEndpoints.sawApiHost}/options/diameters`);
      url.searchParams.append('surfaceTreatments', this.selectedSurfaceTreament);
      url.searchParams.append('quality', this.selectedQuality);
      
      axios.get(url).then(res => {
        
        // BESPOKE KOB-377
        // In the unlikely case we retrieve no diameters, reload the configurator 5 seconds later to try again, but only attempt that once 
        if (res.data.length == 0 && !this.reloaded) {          
          this.reloading = true;  
          this.reloaded = true;
          console.log('reloaded configurator');
          setTimeout(() => {
          this.reloadConfigurator();}, 5000);
        } else {
          this.reloading = false;
          this.diameters = res.data;
          this.disableForm = false;
        }
      });
    },
    getQualities() {
      this.disableForm = true;
      this.selectedPipe = null;

      var url = new URL(`${this.bespokeEndpoints.sawApiHost}/options/qualities`);
      url.searchParams.append('diameter', this.selectedDiameter);
      
      axios.get(url).then(res => {
        this.qualities = res.data;
        this.disableForm = false;
      });

    },
    getSurfaceTreatments() {
      this.disableForm = true;

      var url = new URL(`${this.bespokeEndpoints.sawApiHost}/options/surfaceTreatments`);
      url.searchParams.append('language', this.language.toLowerCase());
      url.searchParams.append('surfaceTreatments', this.selectedSurfaceTreament);
      url.searchParams.append('diameter', this.selectedDiameter);
      url.searchParams.append('quality', this.selectedQuality);

      axios.get(url).then(res => {

        var surfaceTreatments = res.data;

        // The key of the surface treatment could possibly be an empty string, which means to not apply
        // a surface treatment. However we do want to show a user friendly option for this instead of an empty string
        // which means we should remove the { "" : false } and replace it with { this.noSurfaceTreatmentAvailableValue: "false"}
        var indexOfNoSurfaceTreatment = Object.keys(surfaceTreatments).findIndex(key => key === "");
        if (indexOfNoSurfaceTreatment > -1) {
          delete surfaceTreatments[""];
          surfaceTreatments = {
            [this.noSurfaceTreatmentAvailableValue] : false,
            ...surfaceTreatments
          } 
        }
        
        // If currently binded selected Surface Treatment is not available after 
        // changing the diameter or qualtity, reset the binded surfaceTreatment
        // since this should disable the calculate button and force the user to select a new surface treatment
        Object.keys(surfaceTreatments).forEach(x => {
          console.log(x, this.selectedSurfaceTreament);
        })
        
        if (Object.keys(surfaceTreatments).findIndex(key => key === this.selectedSurfaceTreament) === -1) {          
          this.selectedSurfaceTreament = '';
        }        
        
        this.surfaceTreatments = surfaceTreatments;
        
        this.setDoubleFinish();
        this.disableForm = false;

      })

    },
    calculatePipe() {      
      this.disableForm = true;

      var endpoint = this.getSawlistEndpoint();
      axios.get(endpoint).then(res => {
        this.selectedPipe = res.data;
        this.calculatedPrice = this.selectedPipe.productPrice * this.quantity * this.serviceCostMultiplier;
        this.disableForm = false;

      }).catch(res => {
        window.updateErrorMessage(window.vue.translations.text.text_calc_error);
        this.disableForm = false;
      });
    },
    orderPipe() {      
      var payload = {
        ProductCode: this.selectedPipe.sku,
        Diameter: this.selectedDiameter,
        Quality: this.selectedQuality,
        DoubleFinish: this.isDoubleFinish ? "Ja" : "Nee",
        SurfaceTreatment: this.selectedSurfaceTreament === this.noSurfaceTreatmentAvailableValue ? this.noSurfaceTreatmentAvailableValue : this.selectedSurfaceTreament,
        Quantity: this.quantity,
        Length: this.length,
        PricePer100: this.formattedPricePer100,
        TotalPrice: this.formattedPrice
      };

      var serviceCostObj = this.expeditedServiceCosts.find(x => x.id === this.selectedExpeditedServiceCostId);
      payload.ExpeditedService = serviceCostObj.desc;

      axios.post("/Bespoke/Webmethods/ProductListWebmethods.aspx/OrderCustomPipeProduct", { pipeConfiguration: payload }).then(res => {        
        this.showConfirmOrder = true;
      });
    },
    closeError() {
      this.showErrorMessage = false;
    },
    confirmOrder() {
      this.showConfirmOrder = false;
      this.selectedPipe = null;
    },
    reloadConfigurator() {
      this.getDiameters();
      this.getQualities();
      this.getSurfaceTreatments();
    }
  },
  created() {   
    let urlParams = new URLSearchParams(window.location.search);

    var quality = urlParams.get("KW");
    var diameter = urlParams.get("DIAM");

    if (quality) {
      this.selectedQuality = quality;
    }

    if (diameter) {
      this.selectedDiameter = diameter;
    }
    
    this.getDiameters();
    this.getQualities();
    this.getSurfaceTreatments();
  },
  updated() {
    this.setFormIsValid();
  }
}
</script>

<style>

</style>
