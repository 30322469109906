import axios from 'axios';
import { getUrlParams, createUrlParams } from 'src/utilities/url';

const state = {
    searchConfig: {
        UserId: null,
        Category: null,
        StringProperties: null,
        NumericProperties: null,
        StringAggregations: null,
        NumericAggregations: null,
        ExtraQueries: []
      },
    filters: [],
    activeFilters: {},
    queryString: '',
    searchPage: '',
    productKeywords: [],
    exactKeyword: null,
    doubleFinishSpecVal: ""
};

const getters = {  
    activeFilters (state) {
      return state.activeFilters;
    },
    queryString (state) {
      return state.queryString;
    },  
    productKeywords (state) {
      return state.productKeywords;
    },
    exactKeyword(state) {
      return exactKeyword;
    },
    doubleFinishSpecVal(state) {
      return state.doubleFinishSpecVal;
    },
    searchConfig (state) {
        return {
          UserId: null,
          Category: state.searchConfig.Category,
          StringProperties: state.activeFilters,
          NumericProperties: null,
          StringAggregations: state.searchConfig.StringAggregations,
          NumericAggregations: null,
          ExtraQueries: state.searchConfig.ExtraQueries
        }
      }
};

const mutations = {
    initSearchConfig (state, searchConfig) {
        state.searchConfig = { ...searchConfig.searchObject };
        state.doubleFinishSpecVal = searchConfig.doubleFinishSpecVal;
    },
    setFilters (state, filters) {
        filters.forEach(filter => {
          if (state.activeFilters[filter.id] !== undefined) {
            var activeFilterValue = state.activeFilters[filter.id].Values[0];

            filter.value.forEach(x => {
              if (x.key === activeFilterValue) {                
                filter.activeFilter = x.key;
              }
            });
          }
        });
        state.filters = filters;
    },
    updateProductKeywords(state, keywords) {
      state.productKeywords = keywords;
    },
    updateExactProductKeyword(state, keyword) {
      state.exactKeyword = keyword;
    },
    updateActiveFilters (state, { filterActive, filterId, filterValue, language }) {
      let activeFilters = { ...state.activeFilters };

      if (typeof activeFilters[filterId] === 'undefined') {
        activeFilters[filterId] = [];
      }
      if (filterActive) {
        activeFilters[filterId] = {Values: [filterValue], Language: language, PartialSearch: true};
      } else {
        delete activeFilters[filterId];
      }
      state.activeFilters = activeFilters;
    },
    clearAllFilters (state) {
      state.activeFilters = {};
      state.queryString = '';
      state.searchConfig.StringProperties = {};
    },
    setQueryString(state, query) {
      state.queryString = query;
    }
};

const actions = {
    initSawConfigurator ({ commit, dispatch }) {
      dispatch('getFilters');
    },
    getFilters({ commit, getters, dispatch, rootGetters }) {
      let endpoint = rootGetters.bespokeEndpoints.aggregationUrl + '?lang=' + rootGetters.language + '&client=' + rootGetters.clientCode + '&query=' + getters.queryString;      
      axios.post(endpoint, getters.searchConfig)
        .then(res => {
          if (res.data.length > 0) {
            commit('setFilters', res.data);
          }
        });
    },
    updateFilters ({ commit, getters, dispatch, rootGetters }, changedFilter) {
        commit('updateActiveFilters', { ...changedFilter, language: rootGetters.language });
        dispatch('getFilters');
    },
    clearFilters ({ commit, dispatch }) {
      commit('clearAllFilters');
      dispatch('getFilters');
    },
    GetProductSearchKeywords({ commit, getters, dispatch, rootGetters }) {
      if (getters.queryString.length) {
        let endpoint = `${rootGetters.bespokeEndpoints.keywordUrl}/keyword-test?lang=${rootGetters.language}&query=${getters.queryString}&size=10`;

      axios.get(endpoint)
        .then(res => {
          if (res.data.length > 0) {
            var keywords = res.data;
          
            var found = keywords.find(function (x) {
              return x.keywordString == getters.queryString;
            });

            if (found !== undefined) {
              commit('updateExactProductKeyword', found)
              keywords.splice(keywords.indexOf(found), 1)
            } else {
              commit('updateExactProductKeyword', null)              
            }

            commit('updateProductKeywords', keywords);
          } else {
            commit('updateProductKeywords', []);
            commit('updateExactProductKeyword', null)              
          }
        });
      } else {
        commit('updateProductKeywords', []);
        commit('updateExactProductKeyword', null)              
      }      
    },
    updateQueryString({ commit, dispatch }, {query, updateKeywords = true}) {
      commit('setQueryString', query);
      if (updateKeywords) 
        dispatch('GetProductSearchKeywords');
    },
    updateProductKeywords({commit, dispatch}, keywords) {
      commit('updateProductKeywords', keywords);
    },
    updateExactKeyword({commit, dispatch}, keyword) {
            commit('updateExactProductKeyword', keyword)              
    }
};

export default {
    namespaced: true,
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations
  };