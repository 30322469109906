import { formatPrice, calculatePrice } from 'src/utilities/helpers';

export class Product {
  constructor (product) {
    if (product === undefined) {
      this.setEmptyProduct();
    } else {
      this.setProduct(product);
      if (this.images) {
        this.images.unshift(this.imageUrl);
      } else {
        this.images = [this.imageUrl];
      }
    }
  }

  setProduct (product) {
    this.id = product.id;
    this.shortDescription = product.shortDescription;
    this.subTitle = product.subTitle;
    this.longDescription = product.longDescription;
    this.properties = product.properties;
    this.targetUrl = product.targetUrl;
    this.imageUrl = product.imageUrl;
    this.images = product.images;
    this.brand = product.brand;
    this.saleUnit = product.saleUnit;
    this.discountGroup = product.discountGroup;
    this.prices = [];
    this.units = this.setUnits(product.units);
    this.stock = { stockTotal: null };
    this.variantKey = product.variantKey;
    this.productVariants = [];
    this.customStrings = product.customStrings;
    this.customDecimals = product.customDecimals;
    this.customBooleans = product.customBooleans;
    this.customDateTimes = product.customDateTimes;
    this.includedInCustomerLists = [];
    this.imageAltText = product.id;
    this.imageTitleText = product.id;
    this.productImageBanners = [
      { position: 'top-left', values: [], show: false },
      { position: 'top-right', values: [], show: false },
      { position: 'bottom-right', values: [], show: false },
      { position: 'bottom-left', values: [], show: false }
    ];

    // BESPOKE KOB
    // Set stock_product to false, since it has no effect on whether 
    // a product can be ordered when out of stock
    this.customBooleans.STOCK_PRODUCT = false;
    // Cloudinary images
    this.imageUrlSmall = this.getCloudinaryUrl('xs', product.imageUrl);
    this.imageUrlMedium = this.getCloudinaryUrl('md', product.imageUrl);
    this.imageUrlXLarge = this.getCloudinaryUrl('xl', product.imageUrl);
    this.cloudinaryImages = [];
    // END BESPOKE KOB
  }

  setEmptyProduct () {
    this.id = 'NOTLOADED';
    this.shortDescription = '';
    this.subTitle = '';
    this.longDescription = '';
    this.properties = '';
    this.targetUrl = '';
    this.imageUrl = '';
    this.images = [];
    this.brand = '';
    this.variantKey = '';
    this.units = [];
    this.saleUnit = '';
    this.discountGroup = '';
    this.prices = [];
    this.stock = { stockTotal: null };
    this.variantKey = '';
    this.productVariants = [];
    this.customStrings = {};
    this.customDecimals = {};
    this.customBooleans = {};
    this.customDateTimes = {};
    this.includedInCustomerLists = [];
    this.imageAltText = '';
    this.imageTitleText = '';
  }

  setStock (stock) {
    this.stock = stock;
  }

  setSeoInformation (seoInfo) {
    this.imageAltText = seoInfo.ImageAltText;
    this.imageTitleText = seoInfo.ImageTitleText;
  }

  setProductImageBanners (banners) {
    banners.forEach(banner => {
      this.productImageBanners[banner.Location].values.push(banner);
      this.productImageBanners[banner.Location].show = true;
    });
  }

  setUnits (units) {
    if (units) {
      return units.sort((a, b) => (a.quantity > b.quantity) ? 1 : -1)
    } else return null;
  }

  setProductVariants (productVariants) {
    this.productVariants = productVariants;
  }

  setCustomerLists (customerList) {
    this.includedInCustomerLists = customerList;
  }

  // BESPOKE KOB
  // Multiply prices by 100
  setPrices(prices) {

    const getters = window.vuexStore.$store.getters;

    if (prices !== undefined) {
      let formattedPrices = [];
      prices.forEach(priceObj => {
        let priceIncl = null;
        let basePriceIncl = null;

        if (getters.priceType === 3) {
          let vatPerc = 1 + this.customDecimals.VAT_PERCENTAGE / 100;
          priceIncl = formatPrice(priceObj.price * 100 * vatPerc);
          basePriceIncl = formatPrice(priceObj.price * 100 * vatPerc);
        }

        formattedPrices.push({
          rawPrice: calculatePrice(priceObj.price * 100),
          rawBasePrice: calculatePrice(priceObj.basePrice * 100),
          price: formatPrice(priceObj.price * 100),
          basePrice: formatPrice(priceObj.basePrice * 100),
          priceIncl: priceIncl,
          basePriceIncl: basePriceIncl,
          quantity: priceObj.quantity === 0 ? 1 : priceObj.quantity,
          isSalesAction: priceObj.isSalesAction
        });

        // Add prices sales banner to product image banners
        if (priceObj.isSalesAction) {
          const salesBanner = getters.saleBannerColors;
          this.productImageBanners[0].show = true;

          this.productImageBanners[0].values.unshift({
            Text: salesBanner.text,
            Color: salesBanner.color,
            TextColor: salesBanner.textColor,
            Location: 0,
            Type: 0
          });
        }
      });
      this.prices = formattedPrices;
    }
  }
  // END BESPOKE KOB
  

  // BESPOKE KOB
  // Handles cloudinary images
  getCloudinaryUrl (size, imageUrl) {
    const getters = window.vuexStore.$store.getters;
    const dimensions = getters.bespokeGlobalWs.cloudinaryImgSizes.split(',');

    let imgWidth;
    switch(size) {
      case 'xs':
        imgWidth = dimensions[0];
        break;
      case 'sm':
        imgWidth = dimensions[1];
        break;
      case 'md':
        imgWidth = dimensions[2];
        break;
      case 'lg':
        imgWidth = dimensions[3];
        break;
      case 'xl':
        imgWidth = dimensions[4];
        break;
    }

    let url = imageUrl.replace('UTI_IMG_SIZE', imgWidth);
    let urlId = url.replace(/UTI_IMGID/g, this.id);

    return urlId.replace(/,/g, '%2c');
  }

  setCloudinaryUrls () {
    this.images.forEach(image => {
      this.cloudinaryImages.push({ 
        small: this.getCloudinaryUrl('sm', image),
        large: this.getCloudinaryUrl('xl', image),
      });
    });
  }
}
// END BESPOKE KOB
